import { ProductFiltersComponent } from "@/components/inventory/Products/ProductFilters/ProductFilters.component";
import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Product } from "@/interfaces/product";
import { EventBus, pusherEvents } from "@/internal";
import { SharedProductTableMetadata } from "@/metadata/product";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { productActionService } from "@/services/product.actions.service";
import { productService } from "@/services/product.service";
import { PageNavAction } from "@/types/types";
import { BooleanCheck } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./SharedProducts.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    ProductFiltersComponent
  },
  inject: ["$changes"]
})
// tslint:disable: no-string-literal
@ResetService(productService)
export default class SharedProductComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  /**
   * Flag to determine if a filter was applied on last search.
   */
  public activeFilter = false;

  /**
   * Enable filter view for qualified/non qualified products.
   */
  public showFilters = false;

  /**
   * Data to display in the table.
   */
  public products: Product[] = [];

  /**
   * Pagination data.
   */
  public pagination: TablePagination | null = null;

  /**
   * Loading state.
   */
  public loading: boolean = true;

  /**
   * Headers data for the table
   */
  public headers: TableHeader[] = SharedProductTableMetadata;

  /**
   * General actions for subheader component.
   */
  public generalActions: ActionSubheader[] = productActionService.getProductGeneralActions(
    this.openCsvModal,
    this.filtersCallback
  );

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = productActionService.getSharedProductsRowActions(
    this.triggerSearch
  );

  /**
   * Multi actions of products component
   */
  public multiActions: TableAction[] = productActionService.getMultiAction(
    this.triggerSearch
  );

  /**
   * Data to set queries for each API request.
   */
  protected query: HttpQuery = this.setProductQuery();

  /**
   * Table colors.
   */
  protected colors = ["white", "white", "white"];

  constructor() {
    super();
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  /**
   * Takes action on bar code scanning.
   */
  public onBarcodeScanned(code: string) {
    EventBus.$emit("openSearchField", code);
  }

  /**
   * Opens CSV modal.
   */
  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "products" }
    );
  }

  /**
   * Opens filters qualified/non qualified filters.
   */
  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.activeFilter = false;
      delete this.query.is_fully_qualified;
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  /**
   * Updates requests on table page change event.
   * @param pagination: TablePagination
   */
  public onChangePage(pagination: TablePagination) {
    this.triggerSearch({
      ...this.query,
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    });
  }

  /**
   * Updates requests on column name click to sort.
   * @param header: TableHader
   */
  public filter(header: TableHeader) {
    this.triggerSearch({
      ...this.query,
      sort: productService.getSortQuery(header)
    });
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onProductFilter(filter: {
    active: boolean;
    published: boolean;
    details: boolean;
    price: boolean;
  }) {
    this.activeFilter = filter.active;
    if (filter.active) {
      this.query["q[is_active]"] = 1;
      delete this.query["q[is_active_is_false]"];
    } else {
      this.query["q[is_active_is_false]"] = 1;
      delete this.query["q[is_active]"];
    }
    filter.published
      ? (this.query["q[status.published_eq]"] = 1)
      : delete this.query["q[status.published_eq]"];
    filter.details
      ? (this.query["q[status.missing_details_eq]"] = 1)
      : delete this.query["q[status.missing_details_eq]"];
    filter.price
      ? (this.query["q[status.missing_prices_eq]"] = 1)
      : delete this.query["q[status.missing_prices_eq]"];
    this.query.page = 1;
    this.triggerSearch(this.query);
  }

  /**
   * Updates table data based on search component.
   * @param term: string
   */
  public async onSearch(term: string) {
    this.triggerSearch({
      ...this.query,
      page: 1,
      "q[name_contains]": term
    });
  }

  /**
   * Takes action on serch bar close event.
   * @param update: boolean
   */
  public onClose(update: boolean) {
    if (update) {
      this.triggerSearch(this.setProductQuery());
    }
  }

  public async mounted() {
    if (!this.hasPermission(policyList.modifySharedProducts)) {
      this.rowActions[3].icon = "fal fa-eye";
    }
    this.setPageNav({
      title: "shared products",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onClose
      }
    });

    this.$changes.watch(pusherEvents.productTouched, async () => {
      await this.triggerSearch(this.query);
    });

    this.triggerSearch(this.query);
  }

  private async triggerSearch(query?: HttpQuery) {
    this.loading = true;
    this.query = query || this.query;
    try {
      this.products = await productService.get(this.query);
      this.pagination = await productService.getPagination();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
    }
  }

  private setProductQuery(): HttpQuery {
    return {
      ...INITIAL_QUERY_STATE,
      // embed: "batchType,category,batches",
      "q[is_active]": 1
    };
  }

  private beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }
}
